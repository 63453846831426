import React, { useEffect, useState } from "react";
import {  Box, Button, Container, Grid, Popover, Typography} from "@mui/material";
import PageTitle from "../../../common/PageTitle";
import { useTranslation } from "react-i18next";
import MUIDataTable from "mui-datatables";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import AdminUserListService from "../service/AdminUserListService";
import { LIST_LIMIT } from "../../../../constants/constants";
import Pagination from "../../../common/Pagination";
import AdminUsers from "..";
import Skeleton from "../../../common/Skeleton";

const AdminUserRole = (props: any) => {

    const { showSnackbar, selectedRoleNameId ,userId } = props;

    const firstPage = 1;
    const[t] = useTranslation();


    const [limit, setLimit] = useState(LIST_LIMIT.PAGINATION_LIST_LIMIT);
    const [currentPage, setCurrentPage] = useState(firstPage);
    const [anchorElPopover, setAnchorElPopover] = useState(null);
    const [totalPages, setTotalPages] = useState(0);
    const [skeleton, setSkeleton] = useState(true);
    const [totalCount, setTotalCount] = useState(0);
    const [adminUserRole,setAdminUserRole]=useState<any>();
    const [openAdminUserList,setOpenAdminUserList]=useState<boolean>(false);

    const apiData: any[][] = [];

    useEffect(()=>{
        AdminUserRoleApiCall(firstPage, limit);
   
    },[]);

    /**
 *
 * @param currentPage - requesting current page number for pagination
 * @param limit - requesting page limit for pagination
 * @returns - returns API response for admin select user role table
 * @description - function to request select user role get API 
 */
    const AdminUserRoleApiCall = (currentPage: number, limit: any) => { 
        setSkeleton(true);     
        const payload = {};
        const isHitDummyURL = false;
        new AdminUserListService(payload, isHitDummyURL)
            .adminUserRoleList(currentPage, limit)
            .then((res) => {  
                setAdminUserRole(res?.adminUserRoleLogs);
                setTotalCount(res?.pagination?.totalCount);
                setCurrentPage(currentPage);
                setTotalPages(res?.pagination?.totalPages); 
                setSkeleton(false);              
            })
            .catch((err: any) => {
                showSnackbar(err?.message, false);
                setSkeleton(false);
            });
    };

    const columns = [
        {
            name: t("admin_users_list.s_no"),
            options: {
                filter: false,
                sort: false,
            },
        },
        {
            name: t("admin_users_list.role_name"),
            options: {
                filter: false,
                sort: false,
            },
        },
        {
            name: t("admin_users_list.user_count"),
            options: {
                filter: false,
                sort: false,
            },
        },
        {
            name: t("admin_users_list.action"),
            options: {
                filter: false,
                sort: false,
            },
        },     
       
    ];

    /**
 *
 * @param roleNameId - roleNameId for selected user role
 * @returns - returns API response for select user role API
 * @description - function to request select user role POST API 
 */
    const handleSelect = (roleId:number) =>{
        const payload = { userId ,roleId };
        const isHitDummyURL = false;
        new AdminUserListService(payload, isHitDummyURL)
            .selectUserRole()
            .then((res) => {
                showSnackbar(res.message, true);
            })
            .catch((err) => {
                showSnackbar(err?.message, false);
            });
        setOpenAdminUserList(true);

    };

    const actionButton = (
        roleNameId: number,
    ) => {
        return (
            <>
                <Box className="table_btn">
                    <Button
                        className="table_btn"
                        variant="contained"
                        disabled={roleNameId==selectedRoleNameId}
                        onClick={() => handleSelect(roleNameId)}
                    >
                        {t("admin_users_list.select")}
                    </Button>
                </Box>
            </>
        );
    };

    const apiTableData = () => {
        {adminUserRole?.map((item: any, indexItem: number) => {
            apiData[indexItem] = [];
            apiData[indexItem].push(
                (currentPage - 1) * limit + indexItem + 1,
                item?.name,
                item?.userCount,
                actionButton(item?.id)

            );
        });
        }
    };
    apiTableData();

    const handleInsPopoverOpen = (event:any) => {
        setAnchorElPopover(event.currentTarget);
    };
    
    const handleInsPopoverClose = () => {
        setAnchorElPopover(null);
    };

    const handleBackBtn = () => {
        setOpenAdminUserList(true);
    };


    return (   
        <>
            {
                openAdminUserList ? <AdminUsers showSnackbar={showSnackbar}/> : (
                    <>
                        <Popover
                            open={Boolean(anchorElPopover)}
                            anchorEl={anchorElPopover}
                            onClose={handleInsPopoverClose}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                            }}
                            className="instruction_popover"                   
                        >
                            <Box sx={{padding:2}}>
                                <Typography className="rms_rules_headlines"> {t("admin_users_list.select_an_appropriate")}</Typography>
                                {/* <Typography className="rms_rules_notes">{t("admin_users_list.admin_rms_instruction_1")}</Typography> */}
                                <Typography>{t("admin_users_list.admin_rms_instruction_2")}</Typography>
                                <Typography>{t("admin_users_list.admin_rms_instruction_3")}</Typography>  
                            </Box>
                        </Popover>
                        <Container>
                            <Grid container spacing={0} sx={{ py: 5 }}>
                                <Grid item xs={12} sm={6} md={6} lg={6} sx={{ py: 2 }}>
                                    <PageTitle title={t("admin_users_list.select_user_role")} />
                                </Grid>
                                <Grid item xs={12} sm={6} md={6} lg={6} sx={{ py: 6 }}>
                           
                                    <Button
                                        className="float_right table_btn"
                                        size="small"
                                        onClick={handleBackBtn}
                                    >
                                        {t("admin_users_list.back")}
                                    </Button>
                                    <InfoRoundedIcon
                                        onClick={handleInsPopoverOpen}
                                        color="primary"
                                        sx={{ mt: 0 }}
                                        className="rms_instruction_icon float_right"
                                    />
                                </Grid>
                            </Grid>
                        </Container>
                        <Container>
                            <Grid container spacing={2}>
                                <Grid item   xs={12} sm={12} md={12} lg={8} xl={6}>
                                    {skeleton ? (
                                        <Skeleton
                                            varient="rectangular"
                                            width={0}
                                            height={60}
                                            count="10"
                                            my={0}
                                        />
                                    ) : (
                                        <>
                                            <Container className="common_table">
                                                <MUIDataTable 
                                                    title={""}
                                                    columns={columns}
                                                    data={apiData}
                                                    options={{
                                                        pagination: false,
                                                        print: false,
                                                        download: false,
                                                        filter: false,
                                                        search: false,
                                                        viewColumns:false,
                                                        selectableRowsHideCheckboxes: true,                              
                                                        textLabels: {
                                                            body: {
                                                                noMatch: t("table_text.no_match").toString(),
                                                            },
                                                            viewColumns: {
                                                                title: t("table_text.show_columns").toString(),
                                                                titleAria: "Show/Hide Table Columns",
                                                            },
                                                            toolbar: {
                                                                search: t("table_text.search").toString(),
                                                                viewColumns: t("table_text.view_columns").toString(),
                                                            },
                                                        },
                                                        searchPlaceholder: t("table_text.search_placeholder").toString(),
                                                        responsive: "standard",                                                                            
                                                    }}                                       
                                                />
                                                <Pagination
                                                    totalCount={totalCount}
                                                    currentPage={currentPage}
                                                    pageChange={AdminUserRoleApiCall}
                                                    totalPage={totalPages}
                                                    rowsPerPage={limit}
                                                    rowsPerPageChange={setLimit}
                                                />
                                            </Container> 
                                        </>
                                    )
                                    }
                                </Grid>
                                <Grid item xs={0} sm={0} md={0} lg={0} xl={1} className="padding-0"></Grid>
                                <Grid item xs={0} sm={0} md={0} lg={4} xl={4}  className="image_upload_box_container"  >
                                    <Grid className="admin_user_role_box">
                                        <img className="rms_img" alt="cainz" src="./assets/images/adminUserRole.png"/>
                                        <Box className="rms_rules_box">
                                            <Typography className="rms_rules_headlines">{t("admin_users_list.select_an_appropriate")}</Typography>
                                            {/* <Typography className="rms_rules_notes">{t("admin_users_list.admin_rms_instruction_1")}</Typography> */}
                                            <Typography>{t("admin_users_list.admin_rms_instruction_2")}</Typography>
                                            <Typography>{t("admin_users_list.admin_rms_instruction_3")}</Typography>  
                                        </Box>
                                    </Grid>                        
                                </Grid>
                                <Grid item xs={0} sm={0} md={0} lg={0} xl={1}></Grid>
                            </Grid>              
                        </Container>
                    </>
                )
            }
        </>                         
    );
};

export default AdminUserRole;