import * as React from "react";
import { Grid, Typography, Box } from "@mui/material";
import { useTranslation } from "react-i18next";

export default function NotificationMessage(props:any) {
    
    const { isCookiePresent , isMsgRequired }=props;
    
    const [t, i18n] = useTranslation();
   
    return (
        <Box className={`notification_msg ${!isCookiePresent ? "msg_position" :""}`}
            component="div"
        >
            <Grid>
                <Typography
                    variant="inherit"
                    noWrap
                    component="div"
                >                 
                    {isMsgRequired}
                    {/* {t("footer.notification_msg")} */}
                </Typography>
            </Grid>             
        </Box>
    );
}
