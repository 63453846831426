import axios from "axios";
import Cookies from "./Cookies";
import { ROUTES } from "../constants/routes";
import { useAppSelector } from "../redux/hooks";
import { datadogLogs } from "@datadog/browser-logs";

class AxiosUtils {
    private url: string;
    private data: object;
    private headers: object;
    private loggedInUser: string | undefined;
    private BASE_URL;
    static isRefreshing: boolean;
    static refreshPromise: Promise<any>;

    constructor(url: string, data: object = {}, headers: object = {}) {
        this.url = url;
        this.data = data;
        this.headers = headers;
        this.BASE_URL= process.env.REACT_APP_DEV ? process.env.REACT_APP_API_BASE_URL_DEV+"/api" : null;
    }

    async get() {
        const method = "GET";
        const response = await axios({
            method:method,
            url: this.url,
            data: this.data,
            headers: this.headers,
            withCredentials: true
        }).catch((error) => {
            return this.handleCatchOfAxios(error, method);
        });
        localStorage.setItem("systemUpdateNotification",response?.data?.systemUpdateNotification ? response?.data?.systemUpdateNotification : "");
        return response;
    }
    async getNew() {
        const method = "GET";
        const response = await axios({
            method: method,
            url: this.url,
            data: this.data,
            headers: this.headers,
            withCredentials: true
        }).catch((error) => {
            return this.handleCatchOfAxios(error,method);
        });
        localStorage.setItem("systemUpdateNotification",response?.data?.systemUpdateNotification ? response?.data?.systemUpdateNotification : "");
        return response;
    }

    async post() {
        const method = "POST";
        const response = await axios({
            method: method,
            url: this.url,
            data: this.data,
            headers: this.headers,
            withCredentials: true
        }).catch((error) => {
            return this.handleCatchOfAxios(error,method);
        });
        return response;
    }

    async put() {
        const method = "PUT";
        const response = await axios({
            method: method,
            url: this.url,
            data: this.data,
            headers: this.headers,
            withCredentials: true
        }).catch((error) => {
            return this.handleCatchOfAxios(error,method);
        });
        return response;
    }

    async delete() {
        const method = "DELETE";
        const response = await axios({
            method: method,
            url: this.url,
            data: this.data,
            headers: this.headers,
            withCredentials: true
        }).catch((error) => {
            return this.handleCatchOfAxios(error, method);
        });
        return response;
    }
    async refreshAccessToken() {
        const method = "PUT";
        const response = await axios({
            method: method,
            url: `${this.BASE_URL}${ROUTES.REFRESH_TOKEN}`,
            withCredentials: true
        }).catch((error) => {
            return this.handleCatchOfAxios(error,method);
        });
        return response;
    }

    async retryAPICall(method: any, newToken: any) {
        this.headers = { ...this.headers, "access-token": newToken };
        const response = await axios({
            method: method,
            url: this.url,
            data: this.data,
            headers: this.headers,
            withCredentials: true
        }).catch((error) => {
            return this.handleCatchOfAxios(error,method);
        });
        AxiosUtils.isRefreshing = false;
        return response;
    }

    async handleCatchOfAxios(error: any, method: any): Promise<any> {
        if (error?.response?.status >= 400 && error?.response?.status < 599) {
            if (error?.response?.status == 401 && error?.response?.data?.errorCode === "TOKEN_EXPIRED") {
                // Token expired, trigger token refresh
                if (!AxiosUtils.isRefreshing) {
                    AxiosUtils.isRefreshing = true;
                    AxiosUtils.refreshPromise = this.refreshAccessToken(); // Start token refresh
                }
                // Wait for token refresh to complete
                const response = await AxiosUtils.refreshPromise;

                let newToken;

                if (response?.data?.success) {
                    newToken = response?.data?.data?.access_token;
                    new Cookies("access-token", newToken).save(); // Save new access token in cookie
                } else if (response?.success === false) {
                    throw new Error(response?.message);
                } else {
                    throw new Error(response);
                }

                const retriedApiCall = await this.retryAPICall(method, newToken);
                return retriedApiCall;

            }
            else if (error?.response?.status == 400){
                datadogLogs.logger.info(error?.response?.data?.message);
            }
            else if (error?.response?.status == 401) {
                new Cookies("access-token").delete();
                window.location.href = ROUTES.INITIAL_PAGE;
                console.log(error?.response?.data?.message);
                datadogLogs.logger.error(error?.response?.data?.message);
            }
            else if (error?.response?.status == 403){
                datadogLogs.logger.info(error?.response?.data?.message);
            }else if (error?.response?.status == 500){
                datadogLogs.logger.error(error?.response?.data?.message);
            }else if (error?.response?.status == 503 ) {
                window.location.href = ROUTES.UNDER_MAINTENANCE;
                datadogLogs.logger.error(error?.response?.data?.message);
            }
            else {
                datadogLogs.logger.error(error?.response?.data?.message);
            }
            return error?.response?.data?.message;
        }else if(error?.response){
            datadogLogs.logger.info(error?.response?.data?.message);
            return error?.response?.data?.message;
        }
        else{
            datadogLogs.logger.info(error?.message);
            if (error?.code === "ECONNABORTED" || error?.code === "ERR_NETWORK") {
                return "";
            } else {
                return error?.message;
            }
        }
    }
}
export default AxiosUtils;