import React, { useEffect, useRef, useState } from "react";
import {
    Container,
    Grid,
    Button,
    Box,
    Typography,
    TextareaAutosize,
} from "@mui/material";
import UnderMaintenanceService from "../EditMaintenance/services/UnderMaintenanceService";
import SimpleReactValidator from "simple-react-validator";
import { useTranslation } from "react-i18next";


const SystemUpdate = (props:any) =>{

    const {showSnackbar} =props;

    const [t] = useTranslation();
    const [, forceUpdate] = useState(0);

    const [notification , setNotification] =useState<string>("");

    useEffect(() => {
        forceUpdate(1);
    });

    useEffect(()=>{
        getSystemUpdateNotification();
    },[]);

    const handleNotificationArea = (e:any) =>{
        setNotification(e.target.value);
    };

    /**
 *
 * @returns system update notification
 * @description - Api call for get system update notification
 */
    const getSystemUpdateNotification = () => {
        const isHitDummyURL = false;
        new UnderMaintenanceService({}, isHitDummyURL)
            .UnderMaintenanceGetService()
            .then((res:any)=>{
                setNotification(res?.systemUpdateNotification ? res?.systemUpdateNotification :"");
            })
            .catch((err)=>{
                showSnackbar(err?.message, false);
            });
    };

    /**
 *
 * @returns system update notification is updated sucessfully or not
 * @description - Api call for update systemUpdate notification
 */
    const SaveNotification = () => {
        if (validator.current.allValid()) {
            const payload: any = {
                "systemUpdateNotification" : notification,           
            };
            const isHitDummyURL = false;
            new UnderMaintenanceService(payload, isHitDummyURL)
                .UnderMaintenancePostService()
                .then((res) => {
                    localStorage.setItem("systemUpdateNotification",notification ? notification : "");
                    // window.location.reload();
                    showSnackbar(res, true);
                })
                .catch((err) => {
                    showSnackbar(err?.message, false);
                });
        } else {
            validator.current.showMessages();
        }
    };

    
    const handleReset = () => {
        setNotification("");
    };
 

    const validator = useRef(
        new SimpleReactValidator({
            autoForceUpdate: { forceUpdate },
            className:"text-danger",
            messages: {             
                max: t("system_update.maximum_1000_characters"),
            },
        })
    );

    return(
        <Container>
            <Grid container sx={{mt:16}}>
                <Grid item xs={1} sm={2} md={2} lg={2} xl={2}></Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                    <Box className="system_update">
                        <Typography variant="h4">
                            {t("system_update.notification_management")}
                        </Typography>
                        <Typography variant="body2" sx={{my:3}}>
                            {t("system_update.please_write_a_message")}
                        </Typography>
                        {/* <Input
                            className="textarea"
                            id="emptyValue"
                            value={notification}
                            onChange={(e)=>handleNotificationArea(e)}
                        /> */}
                        <TextareaAutosize
                            className="textarea"
                            id="emptyValue"
                            value={notification}
                            onChange={(e)=>handleNotificationArea(e)}
                            minRows={10}/>
                        {validator.current.message("textarea", notification, [
                            { max: 1000 },
                        ])}
                        <Box className="" sx={{mt:2}}>
                            <Button
                                variant="contained"                           
                                sx={{textTransform: "capitalize", mr:2}}
                                onClick={SaveNotification}>
                                {t("system_update.save")}
                            </Button>

                            <Button
                                variant="contained"                           
                                sx={{textTransform: "capitalize"}}
                                onClick={handleReset}>
                                {t("system_update.reset")}
                            </Button>
                        </Box>
                       
                    </Box>                  
                </Grid>      
                <Grid item xs={12} sm={12}  md={6} lg={6} xl={6}>
                    <img className="system_img" alt="cainz"src="./assets/images/update-pana.svg"/>
                </Grid>               
            </Grid>
        </Container>
    );

};
export default SystemUpdate;