export const ROUTES = {
    REGISTER: "/register",
    VERIFY_INVITATION: "/verify-invitation",
    FORGOT_PASSWORD: "/forgot-password",
    SEND_OTP: "/send-otp",
    VERIFY_OTP: "/verify-otp",
    CREATE_PASSWORD: "/create-password",
    INITIAL_PAGE: "/",
    PAGE_NOT_EXIST: "*",
    EXPERIMENT: "/experiment",
    UPDATE_EMAIL: "/update-email",
    EMAIL_OTP: "/email-otp",
    UPDATE_PASSWORD: "/update-password",
    VERIFY_PASSWORD: "/verify-password",
    INVITED_USERS: "/invited-users",
    UNAUTHORIZED_VISIT: "/403",
    ADMIN_USERS: "/admin-users",
    ADMIN_USER_DETAILS: "/admin-users-details",
    INVITE_PAGE: "/invite-page",
    ACCOUNTS_JOURNALS: "/accounts-journals",
    ACTIVITY_LOG: "/activity-log",
    CRON_JOB: "/system-configuration",
    SALE_LIST: "/sale-list",
    PAYMENT_HISTORY: "/payment-history",
    UNDER_MAINTENANCE:"/under-maintenance", 
    EDIT_MAINTENANCE:"/edit-maintenance", 
    ORDER_DETAILS: "/order-details",
    ENCRYPTION_DECRYPTION: "/encryption-decryption-tools",
    ROLE_MANAGEMENT_SYSTEM: "/role-management-systems",
    STUCKED_TRANSACTION:"/stucked-transaction",
    SYSTEM_UPDATE:"/system-update",
    REFRESH_TOKEN: "/admin/refresh/token",
    REPORT : "/report"
};