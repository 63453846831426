import { createTheme } from "@mui/material/styles";

const theme = createTheme({
    typography: {
        fontFamily: [
            "-apple-system",
            "BlinkMacSystemFont",
            "\"Segoe UI\"",
            "Roboto",
            "\"Helvetica Neue\"",
            "Arial",
            "sans-serif",
            "\"Apple Color Emoji\"",
            "\"Segoe UI Emoji\"",
            "\"Segoe UI Symbol\"",
        ].join(","),
    },
    palette: {
        primary: {
            main: "#00684d",
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                text: {
                    backgroundColor: "#00684d",
                    fontSize: "1rem",
                    textTransform:"capitalize",
                    padding: "1rem",
                    color: "#fff",
                    "&:hover": {
                        backgroundColor: "#00684d",
                    },
                },
            },
        },
        MUIDataTable:{
            styleOverrides:{
                root: {
                    boxShadow: "none",
                    backgroundColor: "transparent !important",
                }
            }
        },
        MuiBackdrop: {
            styleOverrides: {
                root: {
                    backgroundColor: "rgba(0, 0, 0, 0.1)"

                }
            }
        },
        MuiFormControl: {
            styleOverrides: {
                root: {
                    display:"inline-flex",
                    flexDirection: "column",
                    position: "relative",
                    minWidth:0,
                    border: 0,
                    verticalLlign: "top",
                    // margin:" 10px 15px",
                }
            }
        },
        MuiTableCell: {
            styleOverrides:{
                root:{
                    verticalAlign: "middle",
                }
            }
        }
    },
});

export default theme;
