import React, { useEffect, useState } from "react";
import Header from "./components/common/Header/";
import Footer from "./components/common/Footer";
import Login from "./components/Auth/Login";
import Register from "./components/Auth/Register";
import ForgotPassword from "./components/Auth/ForgotPassword/SendOTP";
import VerifiedOTP from "./components/Auth/ForgotPassword/VerifyOTP";
import CreatePassword from "./components/Auth/ForgotPassword/CreatePassword";
import VerifyPassword from "./components/Auth/ResetPassword/VerifyPassword";
import SendOTP from "./components/Auth/ForgotPassword/SendOTP";
import theme from "./theme";
import { ThemeProvider } from "@emotion/react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Store from "./components/Store";
import UpdatePassword from "./components/Auth/ResetPassword/UpdatePassword";
import AdminUsers from "./components/User/AdminUserList";
import Cookies from "./utils/Cookies";
import Page404 from "./components/common/Page404";
import InviteUserSeller from "./components/common/invitationPage";
import VerifyInvitation from "./components/common/VerifyInvitation";
import { useAppDispatch, useAppSelector } from "./redux/hooks";
import { loaderActions } from "./redux/features/loader/loaderSlice";
import AdminUserDetails from "./components/User/AdminUserList/AdminUserDetails";
import AuthService from "./components/Auth/service/AuthService";
import { permissionActions } from "./redux/features/roleBasedPermission/permissionSlice";
import { ProtectedRoute } from "./middleware/ProtectedRoute";
import { permissionCategory } from "./constants/permissionCategory";
import UpdateEmail from "./components/User/AdminUserList/EmailUpdate/UpdateEmail";
import EmailOTP from "./components/User/AdminUserList/EmailUpdate/EmailOTP";
import { ROUTES } from "./constants/routes";
import { commonActions } from "./redux/features/common/commonSlice";
import ActivityLog from "./components/ActivityLog";
import CronJob from "./components/common/CronJob";
import InternetConnection from "./checkConnection";
import PaymentReports from "./components/PaymentReports";
import PaymentHistory from "./components/PaymentHistory";
import UnderMaintenance from "./components/UnderMaintenance";
import EditMaintenance from "./components/EditMaintenance";
import OrderDetail from "./components/OrderDetails";
import UnauthorizedAccess from "./components/common/UnauthorizedAccess";
import EncryptionDecryptionTools from "./components/EncryptionDecryption";
import StuckedTransction from "./components/StuckedTransction";
import SystemUpdate from "./components/SystemUpdate";
import RoleManagementSystem from "./components/RoleManagementSystem";
import { snackbarUtils } from "./utils/snackbarUtils";
import Utility from "./utils/Utility";
import ModalUtility from "./utils/modalUtility";
import { useTranslation } from "react-i18next";
import Report from "./components/Report";

function App() {
    const userType = useAppSelector((state) => state.common.loggedInUserType);


    const dispatch = useAppDispatch();
    const [cookie, setCookie] = useState(new Cookies("access-token").read());
    const [isMsgRequired, setIsMsgRequired] = useState<any>(localStorage.getItem("systemUpdateNotification"));
    const [version, setVersion] = useState<any>(localStorage.getItem("backendVersion"));
    const [isUnderMaintenance,setIsUnderMaintenance] = useState<any>(sessionStorage.getItem("isUnderMaintenance"));
    const [showModal,setShowModal] = useState<any>(false);
    const [modalOpen, setModalOpen] = useState<any>(false);
    const utility = new Utility;
    const [t, i18n] = useTranslation();

    const loginFunc = async (token: string, email: string, name: string, userType: number, perPageLimit: number, roleId: any) => {
        new Cookies("access-token", token).save();
        dispatch(commonActions.SAVE_LOGIN_USER_EMAIL(email));
        dispatch(commonActions.SAVE_LOGIN_USER_NAME(name));
        dispatch(commonActions.SAVE_LOGIN_USER_TYPE(userType));
        dispatch(commonActions.SAVE_PER_PAGE_LIMIT(perPageLimit));
        setCookie(token);
        await getPermissions(roleId);
    };

    const getPermissions = async (roleId: any) => {
        if (roleId) {
            new AuthService({}, false)
                .getRolePermissions(roleId)
                .then((res) => {
                    dispatch(permissionActions.SET_PERMISSIONS(res));
                })
                .catch((err) => {
                    showSnackbar(err?.message, false);
                });
        } else {
            new AuthService({}, false)
                .getRolePermissions(0)
                .then((res) => {
                    dispatch(permissionActions.SET_PERMISSIONS(res));
                })
                .catch((err) => {
                    showSnackbar(err?.message, false);
                });
        }
    };

    const logoutFunc = () => {
        setCookie("");
    };

    // const showSnackbar = (message: string, success: boolean, autoClose: boolean) => {
    //     dispatch(
    //         snackbarActions.SHOW_SNACKBAR({
    //             show: true,
    //             message,
    //             success,
    //             autoClose
    //         })
    //     );
    // };
    const showSnackbar = (message: string, success: boolean, autoClose = true) => {
        snackbarUtils(dispatch,message, success, autoClose);
    };    
    const handleLoader = (message: boolean) => {
        dispatch(loaderActions.SHOW_LOADER(message));
    };

    useEffect(() => {
        utility.connectionToSSE(setIsMsgRequired,setVersion,setIsUnderMaintenance);
    }, []);
    
    useEffect(() => {
        const storedBackendVersion = localStorage.getItem("backendVersion");
        if (storedBackendVersion != version) {
            window.history.pushState(null, "", window.location.pathname);
            setModalOpen(true);
            localStorage.setItem("backendVersion", version);
        }
    }, [version]);

    useEffect(() => {
        const storedIsUnderMaintenance = sessionStorage.getItem("isUnderMaintenance");
        if (storedIsUnderMaintenance != isUnderMaintenance) { 
            setShowModal(isUnderMaintenance);
            if(isUnderMaintenance === "false" && cookie){
                window.location.reload();
            } 
            sessionStorage.setItem("isUnderMaintenance", isUnderMaintenance);
        }
    }, [isUnderMaintenance]);

    const routesListWithoutLogin = [
        {
            path: ROUTES.REGISTER,
            element: (
                <Register
                    showSnackbar={showSnackbar}
                    handleLoader={handleLoader}
                    login={loginFunc}
                />
            ),
        },
        { path: ROUTES.VERIFY_INVITATION, element: <VerifyInvitation /> },
        {
            path: ROUTES.FORGOT_PASSWORD,
            element: <ForgotPassword handleLoader={handleLoader} />,
        },
        {
            path: ROUTES.SEND_OTP,
            element: (
                <SendOTP showSnackbar={showSnackbar} handleLoader={handleLoader} />
            ),
        },
        {
            path: ROUTES.VERIFY_OTP,
            element: (
                <VerifiedOTP showSnackbar={showSnackbar} handleLoader={handleLoader} />
            ),
        },
        {
            path: ROUTES.CREATE_PASSWORD,
            element: (
                <CreatePassword
                    showSnackbar={showSnackbar}
                    handleLoader={handleLoader}
                />
            ),
        },
        {
            path: ROUTES.INITIAL_PAGE,
            element: (
                <Login
                    login={loginFunc}
                    hideLoader={handleLoader}
                    showSnackbar={showSnackbar}
                    handleLoader={handleLoader}
                    setIsMsgRequired={setIsMsgRequired}
                />
            ),
        },
        {
            path: ROUTES.PAGE_NOT_EXIST,
            element: <Navigate to={ROUTES.INITIAL_PAGE} replace />,
        },


    ];


    const routesListAfterLogin = [

        {
            category: permissionCategory.STORE_MANAGEMENT,
            path: ROUTES.INITIAL_PAGE,
            element: (
                <Store showSnackbar={showSnackbar} handleLoader={handleLoader} />
            ),
        },
        {
            category: permissionCategory.SUPER_ADMIN,
            path: ROUTES.STUCKED_TRANSACTION,
            element: (
                <StuckedTransction showSnackbar={showSnackbar} handleLoader={handleLoader} />
            )
        },
        {
            category: permissionCategory.SUPER_ADMIN,
            path: ROUTES.ENCRYPTION_DECRYPTION,
            element: (
                <EncryptionDecryptionTools showSnackbar={showSnackbar} handleLoader={handleLoader} />
            )
        },
        {
            category: permissionCategory.COMMON,
            path: ROUTES.UPDATE_EMAIL,
            element: (
                <UpdateEmail showSnackbar={showSnackbar} handleLoader={handleLoader} />
            ),
        },
        {
            category: permissionCategory.COMMON,
            path: ROUTES.EMAIL_OTP,
            element: (
                <EmailOTP
                    showSnackbar={showSnackbar}
                    handleLoader={handleLoader}
                    logout={logoutFunc}
                />
            ),
        },
        {
            category: permissionCategory.COMMON,
            path: ROUTES.UPDATE_PASSWORD,
            element: (
                <UpdatePassword
                    showSnackbar={showSnackbar}
                    handleLoader={handleLoader}
                />
            ),
        },
        {
            category: permissionCategory.COMMON,
            path: ROUTES.VERIFY_PASSWORD,
            element: (
                <VerifyPassword
                    showSnackbar={showSnackbar}
                    handleLoader={handleLoader}
                />
            ),
        },
        {
            category: permissionCategory.ADMIN_USER_MANAGEMENT,
            path: ROUTES.ADMIN_USERS,
            element: (
                <AdminUsers showSnackbar={showSnackbar} handleLoader={handleLoader} />
            ),
        },
        {
            category: permissionCategory.ADMIN_USER_MANAGEMENT,
            path: `${ROUTES.ADMIN_USER_DETAILS}/:id`,
            element: (
                <AdminUserDetails
                    showSnackbar={showSnackbar}
                    handleLoader={handleLoader}
                />
            ),
        },
        {
            category: permissionCategory.COMMON,
            path: ROUTES.ADMIN_USER_DETAILS,
            element: (
                <AdminUserDetails
                    showSnackbar={showSnackbar}
                    handleLoader={handleLoader}
                />
            ),
        },
        {
            category: permissionCategory.COMMON,
            path: ROUTES.INVITE_PAGE,
            element: (
                <InviteUserSeller
                    showSnackbar={showSnackbar}
                    handleLoader={handleLoader}
                />
            ),
        },
        {
            category: permissionCategory.COMMON,
            path: ROUTES.VERIFY_INVITATION,
            element: <VerifyInvitation />,
        },
        {
            category: permissionCategory.COMMON,
            path: ROUTES.PAGE_NOT_EXIST,
            element: <Page404 />,
        },
        {
            category: permissionCategory.SUPER_ADMIN,
            path: ROUTES.ACTIVITY_LOG,
            element: <ActivityLog showSnackbar={showSnackbar} />,
        },{
            category: permissionCategory.SUPER_ADMIN,
            path: ROUTES.REPORT,
            element: (
                <Report showSnackbar={showSnackbar} />
            )
        },
        
        {
            category: permissionCategory.SUPER_ADMIN,
            path: ROUTES.EDIT_MAINTENANCE,
            element: <EditMaintenance showSnackbar={showSnackbar} />,
        },
        {
            category: permissionCategory.PAYMENT_MANAGEMENT,
            path: ROUTES.SALE_LIST,
            element: <PaymentReports showSnackbar={showSnackbar} />,
        },
        {
            category: permissionCategory.PAYMENT_MANAGEMENT,
            path: ROUTES.PAYMENT_HISTORY,
            element: <PaymentHistory showSnackbar={showSnackbar} handleLoader={handleLoader} />,
        },
        {
            category: permissionCategory.SUPER_ADMIN,
            path: ROUTES.CRON_JOB,
            element: (
                <CronJob
                    showSnackbar={showSnackbar}
                    handleLoader={handleLoader}
                />
            ),
        },
        {
            category: userType == 0 ? permissionCategory.SUPER_ADMIN : permissionCategory.COMMON,
            path: ROUTES.UNDER_MAINTENANCE,
            element: userType == 0 ? (
                <EditMaintenance showSnackbar={showSnackbar} />
            ) : (
                <UnderMaintenance showSnackbar={showSnackbar} />
            ),
        },
        {
            category: permissionCategory.PAYMENT_MANAGEMENT,
            path: `${ROUTES.ORDER_DETAILS}/:id/:code`,
            element: (
                <OrderDetail showSnackbar={showSnackbar} />
            ),
        },
        {
            category: permissionCategory.SUPER_ADMIN,
            path: ROUTES.ROLE_MANAGEMENT_SYSTEM,
            element: (
                <RoleManagementSystem showSnackbar={showSnackbar} handleLoader={handleLoader} />
            ),
        },
        {
            category: permissionCategory.COMMON,
            path: ROUTES.UNAUTHORIZED_VISIT,
            element: (
                <UnauthorizedAccess />
            ),
        },
        {
            category: permissionCategory.SUPER_ADMIN,
            path: ROUTES.SYSTEM_UPDATE,
            element: (
                <SystemUpdate showSnackbar={showSnackbar} />
            )
        },
    ];


    return (
        <>
            {(showModal && userType !== 0 && cookie) && <ModalUtility msg={"Website has been put under maintenance"} modalOpen={isUnderMaintenance == "true" ? true : false} setModalOpen={setIsUnderMaintenance} isReloadNeeded={true}/>}
            { modalOpen && <ModalUtility msg={t("pr.version")} modalOpen={modalOpen} setModalOpen={setModalOpen} isReloadNeeded={true}/>}
            <div className="App appMinHeight">
                <BrowserRouter>
                    <InternetConnection />
                    <ThemeProvider theme={theme}>
                        {cookie ? <Header logout={logoutFunc} setIsMsgRequired={setIsMsgRequired} isMsgRequired={isMsgRequired} /> : null}
                        {cookie ? (
                            <Routes>
                                {routesListAfterLogin.map((item, loginIndex) => (
                                    <Route
                                        key={loginIndex}
                                        path={item.path}
                                        element={<ProtectedRoute category={item.category}>
                                            {item.element}
                                        </ProtectedRoute>} />
                                ))}
                            </Routes>
                        ) : (
                            <Routes>
                                {routesListWithoutLogin.map((item, index) => (
                                    <Route key={index} path={item.path} element={item.element} />
                                ))}
                            </Routes>
                        )}
                    </ThemeProvider>
                </BrowserRouter>
            </div>
            <Footer isCookiePresent={cookie} isMsgRequired={isMsgRequired}  />
        </>

    );
}
export default App;
