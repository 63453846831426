import { API } from "../../../config";
import OrderLevelReportType from "../../../interfaces/OrderLevelReportInterface";
import AxiosUtils from "../../../utils/AxiosUtils";
import Cookies from "../../../utils/Cookies";
import { mockAPIPath, serverAPIPath } from "../config/apiPath";

class ReportService {
    private payload: object;
    private isHitDummyURL: boolean;
    private headers: object;

    constructor(payload = {}, isHitDummyURL = false) {
        this.payload = payload;
        this.isHitDummyURL = isHitDummyURL;
        this.headers = { "access-token": new Cookies("access-token").read() };
    }

    async orderLevelReport(storeCode:any) {
        const response = this.isHitDummyURL
            ? await new AxiosUtils(
                `${mockAPIPath.ORDER_LEVEL_REPORT}`,
                this.payload
            ).get()
            : await new AxiosUtils(
                `${API.BASE_URL}${serverAPIPath.ORDER_LEVEL_REPORT}?storeCode=`+storeCode,
                this.payload,
                this.headers
            ).get();
        if (response?.data?.success) {         
            const businessReport: OrderLevelReportType = response?.data?.data;
            return businessReport;
        } else if (response?.success == false) {
            throw new Error(response?.message);
        } else {
            throw new Error(response);
        }
    }

    async downloadOrderRepoart(
        storeCode:any,
    
    ) {
        const response = this.isHitDummyURL
            ? await new AxiosUtils(
                `${mockAPIPath.ORDER_LEVEL_REPORT}`,
                this.payload
            ).get()
            : await new AxiosUtils(
                `${API.BASE_URL}${serverAPIPath.ORDER_LEVEL_REPORT}?download=true&storeCode=`+storeCode,
                this.payload,
                this.headers
            ).get();
      
        if (response?.data?.success) {         
            const businessReport: OrderLevelReportType = response?.data?.data;
            return businessReport;
        } else if (response?.success == false) {
            throw new Error(response?.message);
        } else {
            throw new Error(response);
        }
    }

}

export default ReportService;