import { pdf } from "@react-pdf/renderer";
import { API } from "../config";

class Utility {

    constructor() {
        //
    }

    // add commas between numbers
    numberWithCommas(num: string) {
        num = num?.toString();
        const pattern = /(-?\d+)(\d{3})/;
        while (pattern?.test(num))
            num = num?.replace(pattern, "$1,$2");
        return num;
    }

    //  get current Date and Time stamp
    getCurrentTimestamp = () => {
        const date = new Date();
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        const hours = String(date.getHours()).padStart(2, "0");
        const minutes = String(date.getMinutes()).padStart(2, "0");
        const seconds = String(date.getSeconds()).padStart(2, "0");
        return `${year}${month}${day}${hours}${minutes}${seconds}`;
    };

    // get date 2 months ahead of current Date and config date
    getFormattedDateTime = (requiredDate: Date, configDate?: number) => {
        const year = requiredDate.getFullYear();
        const month = String(requiredDate.getMonth() + 1).padStart(2, "0");
        const date = configDate ? configDate : String(requiredDate.getDate()).padStart(2, "0");
        return `${year}/${month}/${date}`;
    };

    // Convert into  Camel-Case
    camelCase = (str: string) => {
        // converting all characters to lowercase
        const ans: string = str.toString().toLowerCase();
        // Returning string to camelcase
        return ans.split("_").reduce((s, c) => s
            + (c.charAt(0).toUpperCase() + c.slice(1)));

    };

    // convert the value into YYYY MM with JP Format
    getCustomDateYMDJP = (date: string) => {
        const year = date.slice(0, 4);
        const month = date.slice(4, 6);
        const JPDate = year + " 年 " + month + " 月分 ";

        return JPDate;
    };

    // download PDF with view on new Tab on a click
    downloadPdfWithView = async (html: any, fileName: string) => {
        const pdfBlob = await pdf(html).toBlob();
        const pdfUrl = URL.createObjectURL(pdfBlob);
        const link = document.createElement("a");
        const newTab = document.createElement("a");
        link.href = pdfUrl;
        newTab.href = pdfUrl;
        link.setAttribute("download", fileName);
        newTab.setAttribute("target", "_blank");
        document.body.appendChild(link);
        document.body.appendChild(newTab);
        link.click();
        newTab.click();
    };

    // download PDF with a click
    downloadPdfWithoutView = async (html: any, fileName: string) => {
        const pdfBlob = await pdf(html).toBlob();
        const pdfUrl = URL.createObjectURL(pdfBlob);
        const link = document.createElement("a");
        link.href = pdfUrl;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
    };

    // print PDF
    printPdf = async (html: any, filename: string) => {
        const pdfBlob = await pdf(html).toBlob();
        const pdfUrl = URL.createObjectURL(pdfBlob);
        const link = document.createElement("a");
        link.href = pdfUrl;
        const pdfWindow = window.open(pdfUrl, filename, "popup");
        pdfWindow?.document.close();
        pdfWindow?.focus();
        return false;
    };
    // server sent event 
    connectionToSSE = async (setIsMsgRequired:any,setVersion:any,setIsUnderMaintenance:any) => {
        let eventSource: EventSource | null  = null;
 
        function connectToSSE() {         
            eventSource = new EventSource(API.SSE_API_PATH);
            eventSource.addEventListener("notification", (e) => {
                setIsMsgRequired(e.data);
                localStorage.setItem("systemUpdateNotification", e.data);
            });
            eventSource.addEventListener("version", (e) => {
                setVersion(e.data);
            });
            eventSource.addEventListener("maintenance", (e) => {
                setIsUnderMaintenance(e.data);
            });
            eventSource.addEventListener("error", () => {
                reconnectToSSE();
            });   
        }

        function reconnectToSSE() {
            setTimeout(() => {
                connectToSSE();
            }, 3000);
        }
        connectToSSE();

        return () => {
            if (eventSource != null) {
                eventSource.close();
            }
        };
    };
}
export default Utility;